<template>
  <div class="home">
    <div class="header">
      <div class="lang-icon" @click="toLang">
        <img v-if="city" :src="city" alt="" srcset="" />
      </div>
      <div class="logo">
        <img src="../../assets/logo.png" alt="" srcset="" />
      </div>
      <div class="search-icon" @click="toSearch">
        <img src="../../assets/search.png" alt="" srcset="" />
      </div>
    </div>
    <div class="content">
      <div class="filter">
        <div
          class="filter-item"
          :class="filterIndex == index ? 'active' : ''"
          v-for="(item, index) in filterList"
          :key="index"
          @click="cahngeFilter(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="currency">
        <div
          class="currency-item"
          v-for="(item, index) in currencyList"
          :key="index"
          @click="toDetail(item.symbol)"
        >
          <div class="item-left">
            <img class="item-img-from" :src="item.from_logo" alt="" />
            <img class="item-img-to" :src="item.to_logo" alt="" />
          </div>
          <div class="symbol">{{ item.symbol }}</div>
          <div class="item-info">
            <div class="item-price">{{ item.price }}</div>
            <div class="item-persent" :class="item.rate > 0 ? 'blue' : 'red'">
              {{ item.rate }}%
              <img
                v-if="item.rate > 0"
                class="up"
                src="../../assets/up.png"
                alt=""
              /><img v-else class="up" src="../../assets/down-red.png" alt="" />
            </div>
          </div>
          <div class="item-kLine" @click.stop="aaa">
            <div :id="item.symbol" style="height: 100%; width: 100%"></div>
          </div>
        </div>
      </div>
    </div>
    <vantabbarVue></vantabbarVue>
  </div>
</template>

<script>
import vantabbarVue from "@/components/vantabbar.vue";
import * as echarts from "echarts";
import { PARAMETER } from "@/config/global_data";
import th from "@/lang/th";
export default {
  name: "HomeView",
  components: {
    vantabbarVue,
  },
  data() {
    return {
      filterIndex: null,
      filterList: [
        { name: "FX" },
        { name: "Metals" },
        { name: "Biofules" },
        { name: "Indexes" },
        { name: "Crypto" },
      ],
      currencyList: [],
      lang: "",
      city: "",
      langList: [],
    };
  },
  mounted() {
    this.initData();
    PARAMETER.room = "forex_list";
    if (this.socketApi.wsConnectionOrder.$wsOrder == null) {
      this.socketApi.wsConnectionOrder.initWebSocket();
    } else {
      this.socketApi.wsConnectionOrder.wsSend();
    }
    this.getCity();
  },
  watch: {
    getWsMsg: function (data) {
      if (data.data == "PONG") {
        return;
      }
      const res = data.data;
      const newsData = JSON.parse(res);
      if (newsData.room == "forex_list") {
        newsData.data.forEach((item, index) => {
          this.currencyList.forEach((i, v) => {
            if (i.symbol == item.symbol) {
              i.price = item.price;
              i.rate = item.rate;
            }
          });
        });
      }
    },
  },
  computed: {
    getWsMsg() {
      return this.$store.state.orderWebSocketMsg;
    },
  },
  methods: {
    getCity() {
      this.$api.requestGet("/api/infos").then((res) => {
        this.langList = res.data.languages;
        this.lang = localStorage.getItem("lang");
        console.log(this.lang);
        this.langList.forEach((item, index) => {
          if (item.code == this.lang) {
            console.log(item);
            this.city = item.logo;
          }
        });
      });
    },
    //初始数据获取
    initData() {
      let params = {
        limit: 40,
      };
      if (this.filterIndex != null)
        params.type = this.filterList[this.filterIndex].name;
      this.$api.requestGet("/api/symbols", params).then((res) => {
        this.currencyList = res.data;
        setTimeout(() => {
          this.currencyList.forEach((item, index) => {
            let color = "";
            let color1 = "";
            if (item.rate > 0) {
              color = "#1677ff";
              color1 = "#8bbbff";
            } else {
              color = "#f23c48";
              color1 = "#f33c48";
            }
            this.installLine(item.symbol, item.price_trends, color, color1);
          });
        }, 200);
      });
    },
    //生成折线
    installLine(name, data, color, color1) {
      var myChart = echarts.init(document.getElementById(name));
      myChart.setOption({
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          containLabel: false,
        },
        xAxis: {
          show: false,
          type: "category",
        },
        yAxis: {
          min: "dataMin",
          type: "value",
          show: false,
          axisLabel: {
            formatter: function (value, index) {
              return value.toFixed(5);
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
            xAxisIndex: 0,
            startValue: data.length - 16, // 初始显示第15到第30条数据
            endValue: data.length - 1, // 这里 endValue 为数组最后一项的索引
            height: 10, // 滑块高度
            bottom: -10, // 调整滑块位置，避免遮挡
            handleSize: "100%", // 滑块手柄大小
          },
        ],
        series: [
          {
            color: color,
            data: data,
            type: "line",
            smooth: 0.3,
            showSymbol: false,
            lineStyle: {
              width: 1, // 设置线条宽度以适应小图
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: color1,
                },
                {
                  offset: 1,
                  color: "#fffc",
                },
              ]),
            },
          },
        ],
      });
    },
    cahngeFilter(index) {
      if (this.filterIndex == index) {
        this.filterIndex = null;
        this.initData();
      } else {
        this.filterIndex = index;
        this.initData();
      }
    },
    toDetail(name) {
      this.$router.push("/currency?name=" + name);
    },
    toSearch() {
      this.$router.push("/search");
    },
    toLang() {
      this.$router.push("/lang");
    },
    aaa() {
      return;
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100vw;
  .header {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 4.26667vw;
    box-sizing: border-box;
    position: relative;
    justify-content: space-between;
    img {
      height: 100%;
      width: 100%;
    }
    .lang-icon {
      width: 27px;
      height: 18px;
    }
    .logo {
      position: absolute;
      height: 40px;
      width: 40px;
      left: 50%;
      transform: translateX(-50%);
    }
    .search-icon {
      box-shadow: 0 0 2.93333vw rgba(161, 161, 161, 0.18);
      height: 32px;
      width: 32px;
      padding: 6px;
      box-sizing: border-box;
    }
  }
  .content {
    padding: 0 1.6vw;
    box-sizing: border-box;
    .filter {
      margin-top: 2.66vw;
      padding: 0 1.6vw;
      box-sizing: border-box;
      display: flex;
      overflow: auto;
      font-size: 15px;
      font-weight: 700;
      .filter-item {
        padding: 0 15px;
        box-sizing: border-box;
        border-radius: 15px;
        background-color: rgba(243, 244, 246, 1);
        margin-right: 10px;
      }
      .active {
        background-color: #0166fc;
        color: #fff;
      }
    }
    .currency {
      margin-top: 20px;
      padding-bottom: 100px;
      box-sizing: border-box;
      .currency-item {
        box-shadow: 0 0 6.4vw rgba(161, 161, 161, 0.09);
        width: 100%;
        background-color: #fff;
        padding: 1.33333vw 3.2vw;
        box-sizing: border-box;
        margin-top: 1.06667vw;
        border-radius: 2.66667vw;
        display: flex;
        align-items: center;
        height: 16vw;
        .item-left {
          position: relative;
          width: 38px;
          height: 38px;

          .item-img-from {
            position: absolute;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            bottom: 0;
            left: 0;
            z-index: 10;
          }
          .item-img-to {
            position: absolute;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            top: 0;
            right: 0;
          }
        }
        .symbol {
          width: 70px;
          margin-left: 10px;
          font-size: 15px;
        }
        .item-info {
          text-align: center;
          margin-left: 30px;
          .item-price {
            font-size: 15px;
            line-height: 28px;
          }
          .item-persent {
            font-size: 12px;
            line-height: 14px;
            display: flex;
            align-items: center;
            .up {
              width: 14px;
              height: 14px;
              margin-left: 3px;
            }
          }
        }
        .item-kLine {
          margin-left: auto;
          width: 94px;
          height: 25px;
        }
      }
    }
  }
}
</style>
